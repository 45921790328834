import React from "react";
import GenericSection from "../components/sections/GenericSection";

const Privacy = () => {
  return (
    <GenericSection>
      <div>
        <h2>Privacy Policy</h2>
        <p>
          We collect personal information from you, including information about
          your:
        </p>
        <ul>
          <li>Email Address</li>
          <li>Ethereum Address</li>
        </ul>
        <p>We collect your personal information in order to:</p>
        <ul>
          <li>send marketing material</li>
          <li>send tokens based on the Ethereum blockchain</li>
          <li>send updates about our business</li>
        </ul>
        <h3>Providing some information is optional.</h3>
        <p>
          If you choose not to enter your{" "}
          <b>
            <i>email address</i>
          </b>
          , we'll be unable to send you material about our new content.
        </p>
        <p>
          If you choose not to enter{" "}
          <b>
            <i>Ethereum address</i>
          </b>
          , we'll be unable to send tokens to your Ethereum address
        </p>
        <p>
          You have the right to ask for a copy of any personal information we
          hold about you, and to ask for it to be corrected if you think it is
          wrong.
        </p>
        <p>
          If you’d like to ask for a copy of your information, or to have it
          corrected, please contact us at <b>help@obuuna.com.</b>
        </p>
      </div>
    </GenericSection>
  );
};

export default Privacy;
