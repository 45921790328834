import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import Input from "../elements/Input";
import Button from "../elements/Button";

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
};

const defaultProps = {
  ...SectionProps.defaults,
  split: false
};

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {
  const ERROR_MESSAGE = "Oops, that's embarrassing, please trying again soon.";
  const SUCCESS_MESSAGE = "Awesome! Keep an eye out for the free goodies";
  const [emailInput, setEmailInput] = React.useState("");
  const [ethAddressInput, setEthAddressInput] = React.useState("");
  const [disableSubmit, setDisableSubmit] = React.useState(false);
  const [showErrorMessage, setShowErrorMessage] = React.useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);

  const handleClick = async () => {
    setShowErrorMessage(false);
    setDisableSubmit(true);
    const putMethod = {
      method: "PUT", // Method itself
      headers: {
        "Content-type": "application/json; charset=UTF-8" // Indicates the content
      },
      body: JSON.stringify({
        member: {
          email: emailInput.toString(),
          eth_address: ethAddressInput.toString()
        }
      }) // We send data in JSON format
    };

    // make the HTTP put request using fetch api
    await fetch("https://api.obuuna.com/obuuna-subscribe", putMethod)
      .then(response => response.json())
      .then(data => {
        setShowSuccessMessage(true);
      }) // Manipulate the data retrieved back, if we want to do something with it
      .catch(err => {
        setShowErrorMessage(true);
        setDisableSubmit(false);
      });
  };

  const handleEmailChange = event => {
    setEmailInput(event.target.value);
    setShowErrorMessage(false);
  };

  const handleAddressChange = event => {
    setEthAddressInput(event.target.value);
    setShowErrorMessage(false);
  };

  const verifyCallback = event => {};

  const callback = event => {};

  const outerClasses = classNames(
    "cta section center-content-mobile reveal-from-bottom",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "cta-inner",
    "pt-16",
    "pb-16",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider",
    split && "cta-split"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          {!showSuccessMessage && (
            <div className={classNames("cta-slogan", "center-content")}>
              <h3 className="m-0">Sign up to our newsletter!</h3>
              <h6 className="m-0">
                Add your Ethereum address and get some free goodies!
              </h6>
            </div>
          )}
          <div className="cta-action">
            <ul className={classNames("list-reset", "container-xs")}>
              {showSuccessMessage && (
                <li className={classNames("pt-16", "pb-16")}>
                  <h4>{SUCCESS_MESSAGE}</h4>
                </li>
              )}
              {!showSuccessMessage && (
                <>
                  <li className={classNames("pt-16", "pb-16")}>
                    <Input
                      id="newsletter"
                      type="email"
                      label="Subscribe"
                      labelHidden
                      placeholder="Your best email"
                      onChange={handleEmailChange}
                    ></Input>
                  </li>
                  <li className={classNames("pb-16")}>
                    <Input
                      style={{
                        padding: "1rem"
                      }}
                      id="ethAddress"
                      type="text"
                      label="EthAddress"
                      labelHidden
                      placeholder="0x71C7656EC7ab88b098defB751B7401B5f6d8976F"
                      onChange={handleAddressChange}
                    ></Input>
                  </li>
                  {showErrorMessage && (
                    <h4 className="text-color-error">{ERROR_MESSAGE}</h4>
                  )}
                  <Button
                    tag="a"
                    color="light"
                    wideMobile
                    onClick={handleClick}
                    disabled={disableSubmit}
                  >
                    Keep me updated
                  </Button>
                </>
              )}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;
