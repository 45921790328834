import React from "react";
// import sections
import Hero from "../components/sections/Hero";
import Cta from "../components/sections/Cta";
import GenericSection from "../components/sections/GenericSection";

const Home = () => {
  return (
    <>
      <Hero className="illustration-section-01" />
      <GenericSection />
      <Cta />
    </>
  );
};

export default Home;
